<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Database Connection</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Database Connection</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="handleSubmission()" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{name: 'DatabaseConnections'}">
                <i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="connection_name" class="control-label">Connection Name</label>
              <input type="text" id="connection_name" name="connection_name"
                     v-bind:class="{'has-error' : errors.has('connection_name')}"
                     v-validate="'required'"
                     v-model="item.connection_name" class="form-control"
                     placeholder="Connection name">
              <div class="help text-danger" v-show="errors.has('connection_name')">
                {{ errors.first('connection_name') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="driver" class="control-label">Driver</label>
              <select class="form-control" v-model="item.driver" id="driver">
                <option value="mysql">MySQL</option>
                <option value="sqlsrv">MS SQL Server</option>
              </select>
              <div class="help text-danger" v-show="errors.has('driver')">
                {{ errors.first('driver') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="host" class="control-label">Host</label>
              <input type="text" id="host" name="host"
                     v-bind:class="{'has-error' : errors.has('host')}"
                     v-validate="'required'"
                     v-model="item.host" class="form-control"
                     placeholder="host">
              <div class="help text-danger" v-show="errors.has('host')">
                {{ errors.first('host') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="port" class="control-label">Port</label>
              <input type="text" id="port" name="port"
                     v-bind:class="{'has-error' : errors.has('port')}"
                     v-validate="'required'"
                     v-model="item.port" class="form-control"
                     placeholder="port">
              <div class="help text-danger" v-show="errors.has('port')">
                {{ errors.first('port') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="database" class="control-label">Database</label>
              <input type="text" id="database" name="database"
                     v-bind:class="{'has-error' : errors.has('database')}"
                     v-validate="'required'"
                     v-model="item.database" class="form-control"
                     placeholder="Database">
              <div class="help text-danger" v-show="errors.has('database')">
                {{ errors.first('database') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="username" class="control-label">Username</label>
              <input type="text" id="username" name="username"
                     v-bind:class="{'has-error' : errors.has('username')}"
                     v-validate="'required'"
                     v-model="item.username" class="form-control"
                     placeholder="Username">
              <div class="help text-danger" v-show="errors.has('username')">
                {{ errors.first('username') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="password" class="control-label">Password</label>
              <input type="password" id="password" name="password"
                     v-bind:class="{'has-error' : errors.has('password')}"
                     v-validate="'required'"
                     v-model="item.password" class="form-control"
                     placeholder="password">
              <div class="help text-danger" v-show="errors.has('password')">
                {{ errors.first('password') }}
              </div>
            </div>
            <div class="form-group">
              <label for="status" class="control-label">Status</label>
              <br>
              <el-switch
                v-bind:class="{'has-error' : errors.has('status')}"
                v-model="item.status" id="status" name="status"
                :active-value=1 :inactive-value=0 :width=50>
              </el-switch>
              <div class="help text-danger" v-show="errors.has('status')">
                {{ errors.first('status') }}
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
            <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>

import {DatabaseConnectionAPI} from "@/services/api";
import 'vue2-datepicker/index.css';

export default {
  name: 'DatabaseConnectionForm',
  data: () => ({
    item: {},
    is_loading: false,
    is_edit: false,
    params: {},
  }),
  methods: {
    handleSubmission() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if(this.is_edit) {
            DatabaseConnectionAPI.update(this.params.id, this.item).then(response => {
              this.$router.push({name: 'DatabaseConnections'});
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error.data);
            });
          } else {
            DatabaseConnectionAPI.store(this.item).then(response => {
              this.$router.push({name: 'DatabaseConnections'});
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error.data);
            });
          }
        }
      });
    },
    async itemShow(id) {
      await DatabaseConnectionAPI.show(id).then(item => {
        this.item = item;
      });
    },
    async initialLoad() {
      this.is_loading = true;
      this.params = this.$route.params;
      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        await this.itemShow(this.params.id);
      }
      this.is_loading = false;
    }
  },
  mounted() {
    this.initialLoad()
  }
}
</script>
